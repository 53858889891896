.gradient-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Cover the entire viewport */
  height: 100%; /* Cover the entire viewport */
  z-index: -5;
  background: transparent;
  animation: rotateCW 20s linear infinite;
  pointer-events: none; /* Ensure it doesn't block interactions */
}

.gradient-component::before,
.gradient-component::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 70vw; /* Use vw/vh units to cover half the viewport width */
  height: 70vw; /* Maintain aspect ratio for circles */
}

.gradient-component::before {
  top: 50vh; /* Position to start from the center */
  left: 50vw; /* Adjust based on viewport width to center */
  mix-blend-mode: overlay;
  background: radial-gradient(
    circle closest-side,
    rgba(4, 146, 255, 0.344) 0%,
    /* Darker at the center */ rgba(84, 175, 255, 0) 100%
      /* Fade to transparent */
  );
}

.gradient-component::after {
  bottom: 50vh; /* Position to start from the center */
  right: 50vw; /* Adjust based on viewport width to center */
  mix-blend-mode: overlay;
  background: radial-gradient(
    circle closest-side,
    rgba(255, 147, 255, 0.262) 0%,
    /* Darker at the center */ rgba(255, 71, 255, 0) 100%
      /* Fade to transparent */
  );
}

@keyframes rotateCW {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
