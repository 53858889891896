@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  position: relative;
}

/* body {
  background: url(./assets/galaxy.jpg) no-repeat center center fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  color: white;
  margin: 0;
  line-height: normal;
  background-position: center;
  animation: backgroundPulse 10s infinite ease-in-out;
} */

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -9999;
  background-image: url('./assets/galaxy.jpg');
  background-size: cover;
  background-position: center;
  animation: backgroundPulse 30s infinite ease-in-out;
}

:root {
  /* fonts */
  --font-outfit: Outfit;

  /* font sizes */
  --font-size-lg: 1.13rem;

  /* Colors */
  --label-dark-primary: #fff;
  --color-gray-100: rgba(255, 255, 255, 0.8);
  --color-gray-200: rgba(255, 255, 255, 0.41);

  /* Gaps */
  --gap-lg: 1.13rem;
  --gap-xs: 0.75rem;

  /* Paddings */
  --padding-lg: 1.13rem;
  --padding-3xs: 0.63rem;

  /* border radiuses */
  --br-3xs: 10px;
}

@keyframes backgroundPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
