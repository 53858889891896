.feathered-image {
  width: 100%;
  height: 500px;
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%), linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%), linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%);
  -webkit-mask-composite: source-in;
  mask-composite: intersect;
  object-fit: cover;
  object-position: center;
}
