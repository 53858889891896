.num-widget-container {
  /* Additional styling for your floating container */
}

.num-widget-content {
  text-align: center; /* Center the content */
}

.num-widget-sacred-animation {
  position: relative; /* This creates a new stacking context */
  display: flex; /* Flexbox to center content */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  width: 5rem; /* Match the image size or adjust as needed */
  height: 5rem; /* Match the image size or adjust as needed */
}

.num-widget-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: #ffffff;
  font-weight: bold;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  /* -webkit-text-stroke: 0.2rem rgb(255, 255, 255); */
}

.num-widget-sacred-animation img {
  position: absolute; /* Make sure the image can underlay the number */
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  opacity: 0.5;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 80000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

.num-widget-description {
  font-size: 0.8rem; /* Smaller font size for the description */
  color: #ffffff; /* Matching color scheme */
  margin-top: 5rem; /* Space between number and its description */
  text-align: center; /* Ensure it's centered under the number */
  line-height: 1;
}

.num-widget-box {
  background-color: rgba(0, 0, 0, 0.2); /* Black background with 0.2 opacity */
  border: 2px solid #ffffff; /* White solid stroke */
  border-radius: 20px; /* Round corners */
  padding: 1rem; /* Padding around the content */
  padding-bottom: 2rem;
  display: flex; /* Use flex to center the content */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  margin-top: -1rem; /* Space from the top of the grid item */
  width: 50%; /* Adjust the width as needed */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.pulldown-animation {
  animation: pulldown 3s ease-out forwards; /* Adjust time as needed */
}

/* NumWidget.css */
.lifePathDisplay-base {
  maxheight: '5vh';
  position: 'fixed';
  top: 0; /* Adjust this value if necessary for correct positioning */
  left: '50%';
  transform: 'translateX(-50%)'; /* This centers the component horizontally */
  zindex: 1000;
}

/* Media query for small (sm) screens, typically up to 600px */
@media (max-width: 800px) {
  .num-widget-box {
    padding-right: 50px; /* Increased padding for small screens */
    padding-left: 50px; /* Increased padding for small screens */
  }
}

/* Optionally, if you need even more padding for extra small (xs) screens */

/* Adjust the keyframes to include translateX for centering */
@keyframes pulldown {
  from {
    transform: translateX(-50%) translateY(-100vh); /* Start from above the screen */
  }
  to {
    transform: translateX(-50%) translateY(0); /* Ensure it remains centered */
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes growPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Grow */
  }
  100% {
    transform: scale(1); /* Return to normal */
  }
}

.grow-pulse {
  animation: growPulse 0.5s ease-out; /* Adjust duration and easing as desired */
}

@keyframes textPulse {
  0% {
    font-size: 0;
    opacity: 0;
  }
  50% {
    font-size: 24px;
    opacity: 1;
  }
  100% {
    font-size: 24px;
    opacity: 1;
  }
}
