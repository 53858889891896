/* UrgeAnimation.css */

/* General styling for the Pythagorean grid container */
.urgeGridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5px;
  width: 450px;
  margin: 0 auto;
  padding: 1rem;
}

/* Styling for each cell in the grid */
.urgeGridCell {
  border: #fff solid 1px;
  border-radius: 5px;
  font-size: x-large;
  border-radius: 5px;
  text-shadow: 2px 2px #000000;
  display: flex;
  justify-content: center;
  alignitems: center;
  position: relative;
  background-color: rgba(
    255,
    222,
    245,
    0.1
  ); /* Default background color for cells */
  transition: background-color 400ms ease-in-out; /* Smooth transition for the flashing effect */
}

/* Additional styling for cells that are highlighted (part of the name) */
.urge-selected-cell {
  background-color: rgb(
    255,
    105,
    180
  ); /* Pink background for highlighted cells */
}

/* Styling for cells in the number row */
.urge-number-row-cell {
  background-color: #f2932244; /* Different background color for number row cells */
}

/* Ensures that the background color animation is visible by setting the z-index */
.urgeGridCell::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  z-index: -1;
}
