.num-result-spin-animation-container {
  /* Additional styling for your floating container */
}

.num-result-spin-animation-content {
  text-align: center; /* Center the content */
}

.num-result-spin-animation-sacred-animation {
  position: relative; /* This creates a new stacking context */
  display: flex; /* Flexbox to center content */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  width: 22rem; /* Match the image size or adjust as needed */
  height: 22rem; /* Match the image size or adjust as needed */
}

.num-result-spin-animation-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18rem;
  color: #3b1d4e;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  -webkit-text-stroke: 0.2rem rgb(255, 255, 255);
}

.num-result-spin-animation-sacred-animation img {
  position: absolute; /* Make sure the image can underlay the number */
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  opacity: 0.5;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 80000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

.num-result-spin-animation-description {
  font-size: 0.8rem; /* Smaller font size for the description */
  color: #ffffff; /* Matching color scheme */
  margin-top: 5rem; /* Space between number and its description */
  text-align: center; /* Ensure it's centered under the number */
}

.num-result-spin-animation-box {
  background-color: rgba(0, 0, 0, 0.2); /* Black background with 0.2 opacity */
  border: 2px solid #ffffff; /* White solid stroke */
  border-radius: 20px; /* Round corners */
  padding: 1rem; /* Padding around the content */
  padding-bottom: 2rem;
  display: flex; /* Use flex to center the content */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  margin-top: -1rem; /* Space from the top of the grid item */
  width: 50%; /* Adjust the width as needed */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
