@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

.header {
  padding-top: 60px;
  padding-bottom: 40px;
}

.header h1 {
  font-size: 70px;
  font-weight: 800;
  text-align: center;
}

.numerology-layout {
  display: grid;
  grid-template-rows: 100px 1fr;
  height: 100vh;
}

.num-logo {
  height: 100px;
  //background-color: rgba(124, 240, 151, 0.14);
}

.num-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.num-centered-component-area {
  display: flex;
  justify-content: center;
  align-items: top;
  height: 100vh; /*If you want the section to take up the entire viewport height. Adjust as needed. */
}

.funnel-true {
  display: block;
  grid-template-rows: 100px 1fr;
  height: 100vh;
  // background-color: rgba(1, 225, 9, 0.27);
}

.funnel-false {
  display: none;
}

.num-component-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 100%; /* Ensures that the content stretches to the maximum width if smaller than 1000px */
}

.lifePathDisplay-container {
  position: relative;
  width: 100%; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
}

.font-intro1 {
  font-family: "oswald", sans-serif;
  font-size: 5em;
}
.font-intro2 {
  font-family: "oswald", sans-serif;
  font-size: 2em;
}

.sacred-animation {
  position: relative;
  width: 25rem;
  height: 25rem;
  opacity: 0.3;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 80000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

.sacred-animation img {
}

.lifePathDisplay-container .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20em;
  color: #3b1d4e;

  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  -webkit-text-stroke: 0.2rem rgb(255, 255, 255);
}

.first-name {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Dancing Script", cursive;
  font-size: 10rem;
  font-weight: 700;
  color: #3b1d4e;
  text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
    0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  -webkit-text-stroke: 0.1rem rgb(255, 255, 255);
}

input,
select,
textarea {
  color: #ffffff;
}

.Button-Welcome-Viz {
  text-align: center;
  padding: 50px;
}

.NumWelcome-MasterBox {
  padding: 100px;
  font-weight: 800;
  text-align: center;
}

.welcome-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2;
  width: 100%;
}

.form-wrapper {
  display: "flex";
  margin: 20px;
  flexdirection: "column";
  alignitems: "center";
  gap: 2;
  width: "100%";
}

.play-floating-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 4.5rem;
  padding: 10px;
}

.skip-floating-button {
  position: fixed;
  bottom: 50px;
  left: 50px;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 4.5rem;
  padding: 10px;
}

.pause-play-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 4.5rem;
  padding: 10px;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 4.5rem;
}

.playRecorded-container-old {
  position: fixed;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1000px; /* Optional: Set a maximum width if desired */
  border-radius: 10px;
  text-align: center;
  padding: 20px;
}

.playRecorded-container {
  max-width: 1000px; /* Optional: Set a maximum width if desired */
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.playRecorded-container h2 {
  font-size: 2rem;
}

.LogoComponent-floating {
  position: fixed;
  // top: 10px;
  // left: 10px;
  top: 10px;
  left: 50%vw;
  height: 30px;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}
.LogoComponent-floating img {
  max-height: 80px;
  opacity: 50%;
}
// .pythagoreanGridCell {
//   border: #fff solid 1px;
//   border-radius: 5px;
//   font-size: x-large;
//   border-radius: 5px;
//   text-shadow: 2px 2px #000000;
//   position: relative;
// }

// @for $row from 1 through 4 {
//   @for $col from 1 through 9 {
//     .cell-#{$row}-#{$col} div {
//       animation: fade-in 5s ease-in forwards;
//     }
//   }
// }

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
    box-shadow: 0 0 40px #ffffff7b, -10px 0 50px rgba(198, 179, 198, 0.486),
      10px 0 50px rgba(0, 97, 97, 0.514);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
  }

  to {
    box-shadow: 0 0 50px #ffffff77, -10px 0 80px rgba(153, 0, 153, 0.486),
      10px 0 80px rgba(0, 97, 97, 0.514);
  }
}
