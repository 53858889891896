.text-gradient {
  background: linear-gradient(-45deg, #a155a4, #6355a4, #502870, #db8f37);
  background-size: 300%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 900;
  text-align: center;
  -webkit-text-stroke: 0.5px;
  font-size: calc(35px + 3vw); /* Adjusted font size calculation */
  text-transform: uppercase;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animated_text 10s ease-in-out infinite;
  -moz-animation: animated_text 10s ease-in-out infinite;
  -webkit-animation: animated_text 10s ease-in-out infinite;
}

@keyframes animated_text {
  0% {
    background-position: 0px 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0px 50%;
  }
}
