.floating-characters-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.floating-character {
  position: absolute;
  font-size: 48px;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 1s, transform 1s;
}

.floating-character.active {
  opacity: 1;
  transform: scale(1.5);
  transition: opacity 1s, transform 1s;
  animation: drift 5s linear infinite;
}

@keyframes drift {
  0% {
    transform: scale(1.5) translate(0, 0);
  }
  100% {
    transform: scale(1.5) translate(50px, 50px);
  }
}
